import { useEffect, useState, useRef } from "react";

import { deliveryAPI } from '../../api/deliveryAPI';
import { common } from '../../common/common';

import Spinner from "../../components/spinner";


export default function DeliverDensu() {
    const didMount = useRef(true);

    const [deliveries, setDeliveries] = useState([]);

    const [isProcessing, setIsProcessing] = useState(true);

    useEffect(() => {
        if (didMount.current === true) {

            didMount.current = false;
            setIsProcessing(true);
            deliveryAPI.getDeliveryToDensu().then((data) => setDeliveries(data)).finally(() => setIsProcessing(false));
        }
    }, [])

    return (
        <div className="row">
            <h4 className="text-center">Deliver to Densu</h4>
            <div className="col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
            <div className="col-12 col-sm-12 col-md-8 col-lg-10 col-xl-10 col-xxl-10 table-responsive">
                <table className="table table-hover text-start">
                    <thead className="thead--color">
                        <tr>
                            <td>#</td>
                            <td>Ref #</td>
                            <td className="small">Collected On</td>
                            <td>Center</td>
                            <td>Location</td>
                            <td className="text-end">Normal</td>
                            <td className="text-end">Urgent</td>
                            <td className="text-end">Repeat</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isProcessing === false && deliveries.map((data, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{data.refNo}</td>
                                    <td>{data.driverColDate}</td>
                                    <td>{data.pname}</td>
                                    <td>{data.location}</td>
                                    <td className="text-end">{data.normalQty}</td>
                                    <td className="text-end">{data.urgentQty}</td>
                                    <td className="text-end">{data.repeatQty}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {isProcessing && <div className="text-center"><Spinner /></div>}
            </div>
            <div className="col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
        </div>   
    );
}