import './fullScreenSpinner.css';

export default function FullScreenSpinner() {
    return (
        <div className="row fullScreenSpinner">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <div className="spinner-border m-5" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    );
}