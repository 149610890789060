import { api } from './configs/axiosConfig';

const endPoint = "/collections";

export const collectionAPI = {
    getPendingCollections: async function () {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'GET'
        })

        return response.data;
    },
    saveCollection: async function (actualCollection) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { actualCollection }
        })

        return response.data;
    }
}
