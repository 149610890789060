import { api } from './configs/axiosConfig';

const endPoint = "/deliveries";

export const deliveryAPI = {
    getDeliveryToCustomer: async function () {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'GET'
        })

        return response.data;
    },
    saveDelivery: async function (deliveryNo, pcode) {
        const response = await api.request({
            url: `${endPoint}`,
            method: 'POST',
            data: { deliveryNo, pcode }
        })

        return response.data;
    },
    getDeliveryToDensu: async function () {
        const response = await api.request({
            url: `${endPoint}/deliver-densu`,
            method: 'GET'
        })

        return response.data;
    }
}
