import Navigation from '../../components/navigation';

import User from '../../components/user';
import Home from '../../components/home';

import './mainMenu.css';

export default function MainMenu({ logOut }) {

    const Header = () => {
        return (
            <div>
                <Home />
                <User />
            </div>
        );
    }

    return (
        <>
            <Header />
            <div className="Bg">
                <div className="row">
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
                    <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 col-xxl-10 p-2">      
                        <div className="row contentMainMenu rounded">                        
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 ">
                                <Navigation logOut={logOut} />
                            </div>
                        </div>
                    </div>
                    <div className="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
                </div>
            </div>
        </>
    );
}