import { useEffect, useState } from "react";
import { pcenterAPI } from '../../api/pcenterAPI';

export default function AllPcode({ setSelectedPcode }) {
    const [pcodes, setPcode] = useState([]);

    useEffect(() => {
        pcenterAPI.getPcenters().then((data) => setPcode(data));
    },[])

    const onChangePCode = (e) => {
        setSelectedPcode(e.target.value);
    }

    return (
        <select className="form-select" onChange={onChangePCode}>
            <option value="">Select a center</option>
            {
                pcodes.map((data) => (
                    <option key={data.pCode} value={data.pCode}>{data.pName}</option>
                ))
            }
        </select>
    );
}