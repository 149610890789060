import { useEffect, useState, useRef } from "react";

import { deliveryAPI } from '../../api/deliveryAPI';

import Spinner from "../../components/spinner";
import FullSpinner from "../../components/fullScreenSpinner";

import { toast } from 'react-toastify';

export default function DeliverCustomer() {
    const didMount = useRef(true);

    const [deliveries, setDeliveries] = useState([]);

    const [isFullScreenSpinner, setIsFullScreenSpinner] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);

    useEffect(() => {
        if (didMount.current === true) {

            didMount.current = false;
            getDeliveryDetails();
        }
    }, [])

    const getDeliveryDetails = () => {
        setIsProcessing(true);

        deliveryAPI.getDeliveryToCustomer().then((data) => setDeliveries(data)).
            finally(() => setIsProcessing(false));
    }

    const saveDelivery = (deliveryNo, jobNo, pcode) => {
        if (window.confirm(`Do you want to mark job # ${jobNo}, delivery note # ${deliveryNo} as delivered ?`) === false) return;

        setIsFullScreenSpinner(true);

        deliveryAPI.saveDelivery(deliveryNo, pcode).then(() => {
            toast.success("Successfully saved.");
            getDeliveryDetails();
        }).finally(() => setIsFullScreenSpinner(false));
    }

    return (
        <>
            {isFullScreenSpinner && <FullSpinner /> }
            <div className="row">
                <h4 className="text-center">Deliver to Customer</h4>
                <div className="col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-10 col-xl-10 col-xxl-10 table-responsive">
                    <table className="table table-hover text-start">
                        <thead className="thead--color">
                            <tr>
                                <td>#</td>
                                <td>Customer</td>
                                <td>Location</td>
                                <td>Job #</td>
                                <td>Patient</td>
                                <td>Urgent</td>
                                <td className="text-center">Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isProcessing === false && deliveries.map((data, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{data.cusName}</td>
                                        <td>{data.delLocation}</td>
                                        <td>{data.jobNo}</td>
                                        <td>{data.patientName}</td>
                                        <td>{data.isUrgent === true ? "Yes" : "No"}</td>
                                        <td className="text-center">
                                            <button type="button" className="btn btn-sm btn-success small py-0"
                                                onClick={() => saveDelivery(data.deliveryNo, data.jobNo, data.pcode)}>Mark delivered</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {isProcessing && <div className="text-center"><Spinner /></div>}
                </div>
                <div className="col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
            </div>
        </>
    );
}