import { Link } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

import './navigation.css';

import PendingCollect from '../../pages/pendingCollect';
import DeliverDensu from '../../pages/deliverDensu';
import DeliverCustomer from '../../pages/deliverCustomer';

export default function Navigation({ logOut }) {

    const NavBar = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                        <div className="col-10 col-sm-10 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                            <div className="row ">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-center">
                                    <h1>Courier App</h1>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <Link to="/mainmenu/pending-collect">
                                        <button type="button" className="form-control btn btn-primary">Pending to Collect</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <Link to="/mainmenu/deliver-densu">
                                        <button type="button" className="form-control btn btn-primary">To be Delivered to Densu</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <Link to="/mainmenu/deliver-customer">
                                        <button type="button" className="form-control btn btn-primary">Deliver to Customer</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="row mt-1">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <button type="button" className="form-control btn btn-danger" onClick={logOut}>Sign Out</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 col-sm-1 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Routes>
                <Route path={"*"} element={<NavBar />}></Route>
                <Route path={"/pending-collect"} element={<PendingCollect />}></Route>
                <Route path={"/deliver-densu"} element={<DeliverDensu />}></Route>
                <Route path={"/deliver-customer"} element={<DeliverCustomer />}></Route>
            </Routes>
        </>
    );
}