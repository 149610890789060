import { useEffect, useState, useRef } from "react";

import { collectionAPI } from '../../api/collectionAPI';
import { common } from '../../common/common';

import Spinner from "../../components/spinner";
import FullScreenSpinner from "../../components/fullScreenSpinner";

import { toast } from 'react-toastify';

export default function PendingCollect() {
    const didMount = useRef(true);

    const [collection, setCollection] = useState([]);

    const [isFullScreenSpinner, setIsFullScreenSpinner] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);

    let actualCollection = [];

    useEffect(() => {
        if (didMount.current === true) {

            didMount.current = false;
            getPendingCollections();
        }
    }, [])

    const getPendingCollections = () => {
        setIsProcessing(true);
        collectionAPI.getPendingCollections().then((data) => setCollection(data)).finally(() => setIsProcessing(false));
    }

    const onInputCollection = (refNo, orgQty, newQty, type, e) => {

        // Allow extra collection, confirmed with Densu on 2023.12.14
        //if (+orgQty < +newQty) {
        //    toast.warn("Collection can not exceed doctor entered qty");
        //    newQty = orgQty;
        //    e.target.value = newQty;
        //}

        if (!Number.isInteger(+newQty))
        { newQty = 0; e.target.value = 0; }

        if (+newQty < 0)
        { newQty = 0; e.target.value = 0; }

        for (let row = 0; row < actualCollection.length; row++) {
            if (actualCollection[row].refNo === refNo && actualCollection[row].type === type)
                actualCollection.splice(row, 1);
        }

        if (newQty.length !== 0)
            actualCollection.push({
                refNo, qty:  +newQty, type
            });
    }

    const saveCollection = () => {
        if (actualCollection.length === 0) { toast.warn("Please enter actual collection"); return; }

        if (window.confirm(`Do you want to save ?`) === false) return;

        setIsFullScreenSpinner(true);

        collectionAPI.saveCollection(actualCollection).then(() => {
            toast.success("Successfully Saved");
            clearForm();
            getPendingCollections();
        }).catch((error) => {
            clearForm();
        }).finally(() => setIsFullScreenSpinner(false));
    }

    const clearForm = () => {
        actualCollection = [];
        let elements = document.getElementsByTagName("input");
        
        for (let ii = 0; ii < elements.length; ii++) {
            if (elements[ii].type === "number") {
                elements[ii].value = "";
            }
        }
    }

    return (
        <>
            {isFullScreenSpinner && <FullScreenSpinner />}
            <div className="row">
                <h4 className="text-center">Pending to Collect</h4>
                <div className="col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
                <div className="col-12 col-sm-12 col-md-8 col-lg-10 col-xl-10 col-xxl-10 table-responsive">
                    <table className="table table-hover text-start">
                        <thead className="thead--color">
                            <tr>
                                <td colSpan="2"></td>
                                <td colSpan="3" className="text-center">To be collected</td>
                                <td colSpan="3" className="text-center thead--color--2">Actual collection</td>
                            </tr>
                            <tr>
                                <td className="small">Ref #</td>
                                <td className="small">Hopital / Doctor / Location</td>
                                <td className="text-end">Normal</td>
                                <td className="text-end">Urgent</td>
                                <td className="text-end">Repeat</td>

                                <td className="text-end thead--color--2">Normal</td>
                                <td className="text-end thead--color--2">Urgent</td>
                                <td className="text-end thead--color--2">Repeat</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isProcessing === false && collection.map((data, index) => (
                                    <tr key={index} className="p-0 m-0">
                                        <td className="p-1 m-1">{data.refNo}</td>
                                        <td className="p-1 m-1">{`${data.cusName} ${(data.cusName === data.subCusName ? "" : " / " + data.subCusName)} / ${data.location}`}</td>
                                        <td className="text-end p-1 m-1">{data.normal}</td>
                                        <td className="text-end p-1 m-1">{data.urgent}</td>
                                        <td className="text-end p-1 m-1">{data.repeat}</td>

                                        <td className="text-end col-2 p-1 m-1">
                                            <input type="number" className="form-control text-end"
                                                onChange={(e) => onInputCollection(data.refNo, data.normal, e.target.value, 'N', e)} />
                                        </td>
                                        <td className="text-end col-2 p-1 m-1">
                                            <input type="number" className="form-control text-end"
                                                onChange={(e) => onInputCollection(data.refNo, data.urgent, e.target.value, 'U', e)} />
                                        </td>
                                        <td className="text-end col-2 p-1 m-1">
                                            <input type="number" className="form-control text-end"
                                                onChange={(e) => onInputCollection(data.refNo, data.repeat, e.target.value, 'R', e)} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {isProcessing && <div className="text-center"><Spinner /></div>}
                    <div className="row">
                        <div className="col-0 col-sm-0 col-md-4 col-lg-4 col-xl-4 col-xxl-4"></div>
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 text-end">
                            <button type="button" className="btn btn-primary" onClick={() => saveCollection()}>Save</button>
                        </div>
                    </div>
                </div>
                <div className="col-0 col-sm-0 col-md-1 col-lg-1 col-xl-1 col-xxl-1"></div>
            </div>
        </>
    );
}